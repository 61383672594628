import { useCallback, useMemo } from 'react'

import patterns from '../patterns'
import { PageStoryblok, SiteStoryblok } from '../storyblok.generated'
import { StoryblokStoryResponse, StoryblokStory } from '../../utils/storyblok'
import { useStory } from '../../utils/useStory'
import BlokZone from '../../components/BlokZone'
import Head from '../../components/Head'
import createSeoHeadParams from '../../utils/createSeoHeadParams'
import HeaderBlok from '../HeaderBlok'
import FooterBlok from '../FooterBlok'
import NewPageContent from '../../components/NewPageContent'
import { PageContext, PageContextProvider } from '../PageContext'
import SectionIndicatorClipped from '../../components/SectionIndicatorClipped'
import convertBloksToPatterns from '../convertBloksToPatterns'
import useIsClientSideRender from '../../utils/useIsClientSideRender'
import ThemeProvider from '../../components/ThemeProvider'
import ThemeGlobalStyles from '../../components/ThemeGlobalStyles'
import { getPageTheme } from '../../utils/getPageTheme'
import { useCurrentHostname } from '../../utils/getHostNameForSite'

interface Props {
  siteStory: StoryblokStoryResponse<StoryblokStory<SiteStoryblok>>
  story: StoryblokStoryResponse<StoryblokStory<PageStoryblok>>
  slug: string
}

const Page = ({ story: pageStory, siteStory, slug }: Props) => {
  const page = useStory(pageStory.story)
  const site = useStory(siteStory.story)
  const { hostName } = useCurrentHostname()
  const bloks = page.content.content

  const isClientSideRender = useIsClientSideRender()

  // Compose the SectionIndicator so we can pass a simpler component to children
  const SectionIndicator = useCallback(
    ({ variant }: { variant: 'light' | 'dark' }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
      const patterns = useMemo(() => convertBloksToPatterns(bloks), [bloks])
      return <SectionIndicatorClipped variant={variant} patterns={patterns} />
    },
    [bloks],
  )

  const context: PageContext = useMemo(
    () => ({
      rels: pageStory.rels,
      links: pageStory.links,
      siteStory,
      SectionIndicator:
        isClientSideRender && page.content.sectionIndicator
          ? SectionIndicator
          : undefined,
    }),
    [
      pageStory,
      siteStory,
      isClientSideRender,
      page.content.sectionIndicator,
      SectionIndicator,
    ],
  )

  const pageTheme = useMemo(() => getPageTheme(page), [page])

  return (
    <ThemeProvider theme={pageTheme}>
      <PageContextProvider value={context}>
        <ThemeGlobalStyles />
        <Head {...createSeoHeadParams(page.content, slug, hostName)} />

        <div data-testid={page.slug} />
        <HeaderBlok
          siteStory={site.content}
          variant={page.content.headerVariant}
        />

        {/** Content may be undefined when the page hasn't been saved yet */}
        {page.content.content && page.content.content.length > 0 ? (
          <BlokZone components={patterns} content={page.content.content} />
        ) : (
          <NewPageContent />
        )}

        <FooterBlok content={site.content.footerBlock[0]} />
      </PageContextProvider>
    </ThemeProvider>
  )
}

export default Page

import { GetStaticProps } from 'next'

import stripUndefined from '../utils/stripUndefined'
import {
  ArticleFeedStoryblok,
  ArticleStoryblok,
  PageStoryblok,
  SiteStoryblok,
} from '../bloks/storyblok.generated'
import {
  getPageStory,
  getSiteStory,
  StoryblokStoryResponse,
  StoryblokStory,
  getStoryblokSpace,
  defaultResolveRelations,
} from '../utils/storyblok'
import { StoryblokPreviewData } from '../storyblokPreview'
import Page from '../bloks/pages/Page'
import SWRProvider from '../components/SWRProvider'
import { getPublicRuntimeConfig } from '../utils/getPublicRuntimeConfig'
import { AlgoliaArticle } from '../utils/algolia/updateNewsIndex'
import { currentSite, Site } from '../sites'

export interface StaticProps {
  siteStory: StoryblokStoryResponse<StoryblokStory<SiteStoryblok>>
  pageStory: StoryblokStoryResponse<StoryblokStory<PageStoryblok>>
  fallback: Record<string, unknown>
  slug: string
}

export const getStaticPaths = async () => {
  return {
    // TODO
    paths: [],
    fallback: 'blocking',
  }
}

type AllPageTypes = PageStoryblok | ArticleStoryblok

const searchFeedArticles = async (
  page: AllPageTypes,
  slice: ArticleFeedStoryblok,
) => {
  if (!process.env.ALGOLIA_NEWS_INDEX) {
    throw new Error('Missing env var: ALGOLIA_NEWS_INDEX')
  }

  if (slice.mode === 'Manual') {
    return { hits: [] }
  }

  // Load  here to ensure it is not in main bundle & only loaded when we have automatic news
  const getAlgoliaClient = await import(
    '../utils/algolia/getAlgoliaSearchClient'
  )

  const newsClient = getAlgoliaClient.default()

  // For Yachting there is some special "Filter" available on each automatic newsFeed, we want the last 3 articles for each category
  if (currentSite === Site.Yachting && slice.mode === 'Automatic') {
    const articleFeedFilters = ['news', 'events', 'milestones']

    const result = await newsClient.searchForHits<AlgoliaArticle>({
      requests: [
        {
          indexName: process.env.ALGOLIA_NEWS_INDEX,
          type: 'default',
          query: '',
          facetFilters: [
            articleFeedFilters.map(
              (articleFeedFilter) => `articleFeedFilter:${articleFeedFilter}`,
            ),
          ].filter((x) => typeof x !== 'undefined'),

          distinct: 3,
        },
      ],
    })
    return {
      hits: result.results?.[0].hits,
    }
  }

  if (slice.variant === 'related') {
    // When it is an article, use the tag provided in the article, otherwise use the filterByTag
    const filterByTag =
      page.component === 'article' ? page.tags?.[0] : slice.filterByTag
    const filterByType = page.component === 'article' ? page.type : undefined

    const result = await newsClient.searchForHits<AlgoliaArticle>({
      requests: [
        {
          indexName: process.env.ALGOLIA_NEWS_INDEX,
          type: 'default',
          query: '',
          facetFilters: [
            filterByType ? `type:${filterByType}` : undefined,
            filterByTag ? `tags:${filterByTag}` : undefined,
          ].filter((x) => typeof x !== 'undefined'),
          page: 0,
          hitsPerPage: 3,
        },
      ],
    })
    return {
      hits: result.results?.[0].hits,
    }
  }

  if (slice.mode === 'Automatic') {
    // When it is an article, use the tag provided in the article, otherwise use the filterByTag
    const filterByTag =
      page.component === 'article' ? page.tags?.[0] : slice.filterByTag

    const result = await newsClient.searchForHits<AlgoliaArticle>({
      requests: [
        {
          indexName: process.env.ALGOLIA_NEWS_INDEX,
          type: 'default',
          query: '',
          facetFilters: [
            filterByTag ? `tags:${filterByTag}` : undefined,
          ].filter((x) => typeof x !== 'undefined'),
          hitsPerPage: 3,
        },
      ],
    })
    return {
      hits: result.results?.[0].hits,
    }
  }

  return { hits: [] }
}

export const enrichData = async (
  page: AllPageTypes,
  content: PageStoryblok['content'],
) => {
  if (!content) {
    return []
  }

  const awaitedData = await Promise.all(
    content.map(async (slice) => {
      switch (slice.component) {
        case 'articleFeed': {
          const results = await searchFeedArticles(page, slice)

          const { hits } = results

          const articles: Partial<
            Pick<
              AlgoliaArticle,
              | 'objectID'
              | 'title'
              | 'summary'
              | 'slug'
              | 'type'
              | 'articleFeedFilter'
              | 'tags'
              | 'image'
              | 'location'
              | 'dateShort'
              | 'publishedAt'
            >
          >[] = hits?.map(
            ({
              objectID,
              title,
              summary,
              slug,
              type,
              articleFeedFilter,
              tags,
              image,
              location,
              dateShort,
              publishedAt,
            }) =>
              stripUndefined({
                objectID,
                title,
                summary,
                slug,
                type,
                articleFeedFilter,
                tags,
                image,
                location,
                dateShort,
                publishedAt,
              }),
          )

          return {
            ...slice,
            automatedArticles: articles,
          }
        }
        default:
          return slice
      }
    }),
  )

  return awaitedData
}

export const getStaticProps: GetStaticProps<
  StaticProps,
  { slugs: string | string[] },
  StoryblokPreviewData
> = async ({ preview, previewData, params, locale }) => {
  if (!locale) {
    throw new Error('Next.js is misconfigured: locale is missing')
  }

  const storyblokFetchOptions = {
    version:
      (process.env.STORYBLOK_VERSION as 'draft' | 'published' | undefined) ||
      (preview ? 'draft' : 'published'),
    fromRelease: previewData?.release,
    cacheVersion:
      previewData?.timestamp ?? (await getStoryblokSpace()).space?.version,
    resolveRelations: [
      // It's not very pretty to preload this data this way as there's no
      // reference from the form blok component, but it's the
      // simplest effective solution by a mile.
      ...defaultResolveRelations,
    ],
  }

  const siteStory = await getSiteStory(locale, storyblokFetchOptions)
  if (!siteStory) {
    throw new Error('Site is misconfigured: site story is missing from CMS')
  }

  const slugPath = Array.isArray(params?.slugs)
    ? params?.slugs?.join('/').toLowerCase()
    : params?.slugs?.toLowerCase()
  const slug = slugPath ?? ''

  const pageStory = await getPageStory(slug, locale, storyblokFetchOptions)

  if (!pageStory || pageStory.story.content.component !== 'page') {
    return {
      notFound: true,
      props: {
        ...getPublicRuntimeConfig(),
      },
      // TODO: Configure via environment variables
      revalidate: 60,
    }
  }

  const enrichedData = await enrichData(
    pageStory.story.content,
    pageStory.story.content.content,
  )

  return {
    props: stripUndefined({
      preview,
      siteStory,
      pageStory: {
        ...pageStory,
        story: {
          ...pageStory.story,
          content: {
            ...pageStory.story.content,
            content: enrichedData,
          },
        },
      },
      fallback: {},
      slug,
      ...getPublicRuntimeConfig(),
    }),
    // TODO: Configure via environment variables
    revalidate: 60,
  }
}

const PageResolver = ({ pageStory, fallback, ...others }: StaticProps) => (
  <SWRProvider fallback={fallback}>
    <Page story={pageStory} {...others} />
  </SWRProvider>
)

export default PageResolver

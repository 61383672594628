import { css } from '@emotion/react'
import styled from '@emotion/styled'

const Container = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing.x20}px ${theme.spacing.x4}px;
    text-align: center;
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
  `,
)
const Text = styled.div(
  () => css`
    margin: 0 auto;
    max-width: 600px;
  `,
)

const NewPageContent = () => (
  <Container>
    <Title>A new page!</Title>
    <Text>
      <p>
        You have created a new page, but it doesn&apos;t have any content yet.
        Add a block to the content to start crafting your brand new page.
      </p>
      <p>
        Don&apos;t forget to set the SEO fields in the SEO tab once you finished
        filling your page.
      </p>
    </Text>
  </Container>
)

export default NewPageContent

import { css, Global } from '@emotion/react'
import type { NextFont } from 'next/dist/compiled/@next/font'
import 'sanitize.css'
import 'sanitize.css/forms.css'
import 'sanitize.css/typography.css'
import { isThemeYachtingWomenInYachtingLandingspage } from '../themes'

const ThemeGlobalStyles = () => (
  <Global
    styles={(theme) => [
      isThemeYachtingWomenInYachtingLandingspage(theme) &&
        css`
          body {
            ${theme.text.body(theme)}
          }
        `,
      // TODO links.globalStyles,
      // TODO headings.globalStyles,
      theme.fonts as Record<string, NextFont>,
    ]}
  />
)

export default ThemeGlobalStyles

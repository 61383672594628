import { PageStoryblok } from '../bloks/storyblok.generated'
import { currentSite, Site } from '../sites'
import { landingspageTheme, womenInYachtingPageTheme } from '../themes'
import { StoryblokStory } from './storyblok'

export const getPageTheme = (page: StoryblokStory<PageStoryblok>) => {
  if (currentSite === Site.Amels && page?.content?.theme === 'landingspage') {
    return landingspageTheme
  }

  if (
    currentSite === Site.Yachting &&
    page?.content?.theme === 'yachting-womenInYachtingLandingspage'
  ) {
    return womenInYachtingPageTheme
  }

  return undefined
}
